import React, { FC } from 'react'
import { Button, Spacer, Text } from '@mtsbank/ui-kit'

interface Props {
  setIsSettingsOpen: (isOpen: boolean) => void
}

export const ChargesEmpty: FC<Props> = ({ setIsSettingsOpen }) => (
  <>
    <Text>На данный момент все счета оплачены. Мы уведомим вас о новых начислениях</Text>
    <Spacer space={32} />
    <Button onClick={() => setIsSettingsOpen(true)} variant="secondary">
      Настроить
    </Button>
  </>
)
