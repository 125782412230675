import * as Yup from 'yup'
import { messages } from '@root/utils/formValidators/messages'
import { messagesTooltip } from '@components/TspPayee/config/messagesTooltip'
import { serviceParamTypeToComponent } from '../utils/serviceUtils'

const { required, max } = messages

export const configChargeSettingsForm = {
  subscriptionNameField: {
    name: 'subscriptionName',
    isClearable: true,
    label: 'Название шаблона',
    component: serviceParamTypeToComponent.StringField,
  },
  payerNameField: {
    name: 'payerName',
    isClearable: true,
    label: 'ФИО плательщика',
    component: serviceParamTypeToComponent.StringField,
  },
  validationSchema: Yup.object().shape({
    subscriptionName: Yup.string().required(required).max(50, max(50)),
    payerName: Yup.string().required(required),
  }),
}
