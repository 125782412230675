import { Payee } from '@open-api/ump/catalog-manager'
import { CardType } from '@root/types/bindings'

export const phoneService: Payee = {
  serviceId: 'phone',
  categoryName: 'mobilianyi_telefon',
  subline: '',
  providerName: 'Оплата мобильных операторов РФ',
  icon: 'phone.png',
  hidden: false,
  searchInvoicesEnabled: false,
  autopaymentDisabled: false,
  payOnCreditEnabled: false,
  payByQrEnabled: false,
  balanceInquiryEnabled: false,
  limits: {
    min: 1,
    max: 30000000,
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  bindingTypes: ['ANONYMOUS_CARD', 'BOUND_CARD', 'EMONEY_ACCOUNT', 'GENERAL_CARD', 'MTS_ACCOUNT', 'VIRTUAL_CARD_LS'],
  params: [
    {
      title: 'Номер телефона',
      name: 'mobilianyi_telefon',
      type: 'PhoneField',
      description: '+7 000 000-00-00',
      hidden: false,
      maxLength: 10,
      serverValidation: false,
      readOnly: false,
      required: true,
      displayOrder: 1,
    },
  ],
}

export const MOBILE_PARAM_NAME = 'mobilianyi_telefon'
export const MTS_GROUP_PARAM = 'NUMBER'
export const REQUIRED_PHONE = 'Укажите мобильный телефон'
export const ERROR_PHONE = 'Неверный мобильный телефон'
export const STATUS_DISABLED_ERROR = 'Прием платежа на этот номер запрещен. Обратитесь к оператору'
export const MTS_UNION_FIELD_NAME = 'Интернет, ТВ и Телефония (МТС и МГТС)'

export const cardIconMapping = {
  [CardType.UNKNOWN]: 'icon-24/Card',
  [CardType.VISA]: 'payment/visa',
  [CardType.MIR]: 'payment/mir',
  [CardType.MASTERCARD]: 'payment/masterCard',
  [CardType.MAESTRO]: 'payment/maestro',
  [CardType.UNIONPAY]: 'payment/unionPay',
  [CardType.VIRTUAL]: 'icon-24/Card',
}

export const UIN_PAYEES = ['17523', '17562']
