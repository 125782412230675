import React, { FC } from 'react'
import { Payee } from '@open-api/ump/catalog-manager'
import { PaymentForm } from '../../PaymentForm/PaymentForm'
import { ChargesPage } from '../../ChargesPage'

interface Props {
  serviceInfo: Payee
  setMdOrder: (mdOrder: string) => void
  setIsOtp: (isShowOtp: boolean) => void
  setPayeeData: (payeeData: Payee) => void
}

export const FormikHandlerWithoutAuth: FC<Props> = ({ serviceInfo, setIsOtp, setMdOrder, setPayeeData }) => {
  const { searchInvoicesEnabled } = serviceInfo || {}

  return (
    <>
      {searchInvoicesEnabled ? (
        <ChargesPage setIsOtp={setIsOtp} setMdOrder={setMdOrder} serviceInfo={serviceInfo} />
      ) : (
        <PaymentForm
          setPayeeData={setPayeeData}
          setIsOtp={setIsOtp}
          setMdOrder={setMdOrder}
          serviceInfo={serviceInfo}
        />
      )}
    </>
  )
}
