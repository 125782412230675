import React, { FC, useEffect, useMemo, useState } from 'react';
import { Field, Formik } from 'formik';
import { isEmpty, noop, omitBy, pickBy } from 'lodash';
import { Dictionary } from '@reduxjs/toolkit';
import { Button, Modal, useDidMount, useToast, Text, theme } from '@mtsbank/ui-kit';
import { Styled as InputControlStyled } from '@mtsbank/ui-kit/build/components/atoms/InputControl/styled';
import { authHelper } from '@root/utils/authHelper/AuthHelper';
import { subscriptionApi } from '@root/api/ump/charge-invoice';
import { uuid } from '@root/utils/generateUuid';
import { Param } from '@open-api/ump/catalog-manager';
import { Subscription } from '@open-api/ump/charge-invoice';
import { Loading } from '@root/components/Loading/Loading';
import { client360Api } from '@root/api/ump/client360';
import { Customer } from '@open-api/ump/client360';
import { ExtendedServiceParameter } from '../types';
import { serviceParamTypeToComponent } from '../utils/serviceUtils';
import { FieldProps } from '../withoutAuthorization/type';
import { ButtonText, ButtonsWrapper, FieldsWrapper, ModalButtonsWrapper, ModalText, Wrapper } from './styled';
import { ChargeSettingsDefaultParamsFields } from '../ChargeSettingsDefaultParamsFields';
import { configChargeSettingsForm } from '../config/configChargeSettingsForm';
const toastOptions = {
  withClose: true,
  withTimeout: true,
  timeout: 3000
};
interface Props {
  subscription?: string;
  invoiceSearchParams: Param[];
  setIsSettingsOpen: (isOpen: boolean) => void;
}
export const ChargesSettings: FC<Props> = ({
  subscription,
  invoiceSearchParams,
  setIsSettingsOpen
}) => {
  const {
    userProfile
  } = authHelper;
  const [subInfo, setSubInfo] = useState<Subscription>(null);
  const [customerInfo, setCustomerInfo] = useState<Customer>(null);
  const [isSaveDeleteLoading, setIsSaveDeleteLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    toast
  } = useToast();
  const {
    validationSchema,
    subscriptionNameField,
    payerNameField
  } = configChargeSettingsForm;
  useDidMount(() => {
    fetchSubscription();
  });
  const fetchSubscription = () => {
    subscriptionApi.getSubscriptionsUsingPOST('mtsmon_site', {
      requestId: uuid(),
      subscriptionId: subscription,
      userId: userProfile?.userId
    }).then(({
      data
    }) => {
      setSubInfo(data.subscriptions[0]);
    });
  };
  const params = useMemo(() => {
    if (subInfo && invoiceSearchParams) {
      return invoiceSearchParams.filter(param => subInfo.subscriptionParams[param.name]);
    }
    return [];
  }, [subInfo, invoiceSearchParams]);
  useEffect(() => {
    if (subInfo) {
      client360Api.getCustomer(userProfile?.mobilePhone).then(({
        data
      }) => {
        setCustomerInfo(data);
      });
    }
  }, [subInfo]);
  const initialValues = useMemo(() => ({
    subscriptionName: subInfo?.subscriptionName || '',
    payerName: subInfo?.payerName || customerInfo?.fullName || '',
    ...params.reduce((preparedParams: Dictionary<string>, param: ExtendedServiceParameter) => ({
      ...preparedParams,
      [param.name]: subInfo.subscriptionParams[param.name]
    }), {})
  }), [params, subInfo, customerInfo]);
  const fields = useMemo(() => params.filter(param => !param.hidden).sort((param: ExtendedServiceParameter) => param.displayOrder).map((param: ExtendedServiceParameter) => {
    const statFunction = noop;
    return ({
      enumId: param.enumId,
      name: param.name,
      label: param.title,
      placeholder: param.description,
      component: serviceParamTypeToComponent[param.type],
      isHidden: param.hidden,
      isClearable: false,
      readOnly: true,
      autoComplete: 'off',
      ...(param.userHint ? {
        controlChildren: <InputControlStyled.CustomToolTip hint={param.userHint} onMouseOver={statFunction} onClick={statFunction} />
      } : {}),
      ...(param.mask || param.regExp ? {
        mask: omitBy({
          regex: param.regExp,
          mask: param.mask
        }, isEmpty)
      } : {})
    } as FieldProps);
  }, ([] as FieldProps[])), [params, initialValues]);
  const handleClickDelete = () => {
    subscriptionApi.deleteSubscriptionsUsingPOST('mtsmon_site', {
      requestId: uuid(),
      subscriptionId: subscription,
      userId: userProfile?.userId
    }).then(({
      data
    }) => {
      if (!data.errorCode) {
        toast('success', 'Данные успешно удалены', '', toastOptions);
        setIsSettingsOpen(false);
      } else {
        toast('error', 'Ошибка удаления данных', data.errorMessage || '', toastOptions);
      }
    }).catch(error => toast('error', 'Ошибка удаления данных', (error.errorMessage as string) || '', toastOptions)).finally(() => setIsSaveDeleteLoading(false));
  };
  const handleSubmit = values => {
    const params = pickBy(values, (_, key) => key !== subscriptionNameField.name && key !== payerNameField.name);
    setIsSaveDeleteLoading(true);
    subscriptionApi
    // todo поправить типизацию сваггера
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .updateSubscriptionsUsingPOST('mtsmon_site', {
      requestId: uuid(),
      ...subInfo,
      subscriptionName: values[subscriptionNameField.name],
      payerName: values[payerNameField.name],
      subscriptionParams: {
        ...params
      }
    }).then(({
      data
    }) => {
      if (!data.errorCode) {
        toast('success', 'Данные успешно сохранены', '', toastOptions);
        setIsSettingsOpen(false);
      } else {
        toast('error', 'Ошибка сохранения данных', data.errorMessage || '', toastOptions);
      }
    }).catch(error => toast('error', 'Ошибка сохранения данных', (error.errorMessage as string) || '', toastOptions)).finally(() => setIsSaveDeleteLoading(false));
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  if (subInfo) {
    return <Wrapper>
        <Formik onSubmit={handleSubmit} initialValues={{
        ...initialValues
      }} validateOnChange={false} validateOnBlur={false} validationSchema={validationSchema}>
          {({
          submitForm
        }) => <>
              <FieldsWrapper>
                <ChargeSettingsDefaultParamsFields />
                {fields.map(field => <Field {...field} />)}
              </FieldsWrapper>
              <ButtonsWrapper>
                <Button isLoading={isSaveDeleteLoading} onClick={submitForm}>
                  Сохранить
                </Button>
                <Button isLoading={isSaveDeleteLoading} onClick={handleModalOpen} variant="ghost">
                  Удалить
                </Button>
              </ButtonsWrapper>
            </>}
        </Formik>
        <Modal size="md" open={isModalOpen} onClose={handleModalClose}>
          <Modal.Body>
            <ModalText>Вы уверены, что хотите удалить шаблон?</ModalText>
          </Modal.Body>
          <Modal.Footer>
            <ModalButtonsWrapper>
              <Button isLoading={isSaveDeleteLoading} onClick={handleModalClose} variant="secondary">
                Отмена
              </Button>
              <ButtonText variant="secondary" isLoading={isSaveDeleteLoading} onClick={handleClickDelete}>
                Удалить
              </ButtonText>
            </ModalButtonsWrapper>
          </Modal.Footer>
        </Modal>
      </Wrapper>;
  }
  return <Loading />;
};