import React, { FC } from 'react';
import { Button, Spacer } from '@mtsbank/ui-kit';
import { Param } from '@open-api/ump/catalog-manager';
import { EWCharge } from '@open-api/ump/charge-invoice';
import { Charge } from '../Charge';
import { Wrapper } from './styled';
interface Props {
  paymentParams: Param[];
  charges: EWCharge[];
  setSelectedCharge: (charge: EWCharge) => void;
  updateChargesResult: () => void;
  subscription?: string;
  setIsSettingsOpen: (isOpen: boolean) => void;
}
export const ChargesList: FC<Props> = ({
  paymentParams,
  charges,
  setSelectedCharge,
  updateChargesResult,
  subscription,
  setIsSettingsOpen
}) => <Wrapper>
    {charges.map((charge, idx) => <Charge key={idx} updateChargesResult={updateChargesResult} setSelectedCharge={setSelectedCharge} paymentParams={paymentParams} charge={charge} />)}
    {subscription && <>
        <Spacer space={32} />
        <Button onClick={() => setIsSettingsOpen(true)} variant="secondary">
          Настроить
        </Button>
      </>}
  </Wrapper>;