import React, { FC } from 'react'
import { Field, useFormikContext } from 'formik'
import { Spacer, Text, theme } from '@mtsbank/ui-kit'
import { configChargeSettingsForm } from '../config/configChargeSettingsForm'

export const ChargeSettingsDefaultParamsFields: FC = () => {
  const { subscriptionNameField, payerNameField } = configChargeSettingsForm

  const { values } = useFormikContext()

  return (
    <div>
      <Field {...subscriptionNameField} />
      <Spacer space={8} />
      <Text color={theme.colors.neutral.g300} size="md">
        {values[subscriptionNameField.name]?.length}/50
      </Text>
      <Spacer space={16} />
      <Field {...payerNameField} />
    </div>
  )
}
