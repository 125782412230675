import React, { FC, useCallback, useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { debounce, isEmpty } from 'lodash';
import { MoneyInput, Text, theme, useToast } from '@mtsbank/ui-kit';
import { FieldProps, WithoutAuthFormValues } from '@components/TspPayee/withoutAuthorization/type';
import { uuid } from '@root/utils/generateUuid';
import { getPcekZone } from '@root/components/CardToCard/utils/helpers';
import { FormatAliasPhone, formatPhoneNumber } from '@root/utils/formatPhoneNumber';
import { ewcatApi } from '@root/api/ump/ewcat';
import { catalogManagerApi } from '@root/api/ump/catalog-manager';
import { Payee } from '@open-api/ump/catalog-manager';
import { Content } from '@root/components/Content/Content';
import { authHelper } from '@root/utils/authHelper/AuthHelper';
import { custApi } from '@root/api/ump/cust';
import { uprsRecommendationApi } from '@root/api/ump/uprs-recommendation';
import { AmountRecommendationResponse } from '@open-api/ump/uprs-recommendation';
import { MOBILE_PARAM_NAME, MTS_GROUP_PARAM } from '../../constants';
import { configPaymentWithoutAuth } from '../../config/configPaymentWithoutAuth';
import { PhoneField } from '../../formFields/PhoneField';
import { isMtsPhone } from '../../utils/helpers';
import { FieldWrapper, FieldsWrapper } from './styled';
interface Props {
  setPayeeData?: (payee: Payee) => void;
  setRecommendedAmount: (recomendation: AmountRecommendationResponse) => void;
}
const scope = 'ALL';
export const PhoneTspField: FC<Props> = ({
  setPayeeData,
  setRecommendedAmount
}) => {
  const {
    values,
    errors
  } = useFormikContext<WithoutAuthFormValues>();
  const {
    operatorSelection
  } = configPaymentWithoutAuth;
  const field = {
    name: 'mobilianyi_telefon',
    label: 'Номер телефона',
    placeholder: '+7 000 000-00-00',
    isHidden: false,
    isClearable: false,
    readOnly: false,
    autoComplete: 'off'
  };
  const zone = getPcekZone();
  let optionsAnonymous;
  if (zone === 'anonymous') {
    optionsAnonymous = {
      headers: {
        'client-Id': 'mts-money-web-mtsid'
      }
    };
  }
  const phoneError = errors[MOBILE_PARAM_NAME];
  const [mobileServiceByPhoneId, setMobileServiceByPhoneId] = useState<string>(null);
  const [mobileServiceByPhoneError, setMobileServiceByPhoneError] = useState(null);
  const [mobileService, setMobileService] = useState<Payee>(null);
  const [mobileServiceError, setMobileServiceError] = useState(null);
  const [currentBalance, setCurrentBalance] = useState(null);
  const {
    isAuthenticated
  } = authHelper;
  const isAuth = isAuthenticated();
  const isBalanceRequired = isAuth && isMtsPhone(mobileService?.serviceId);
  const operatorIsNotDefinedError = !(isEmpty(mobileServiceByPhoneError) && isEmpty(mobileServiceError));
  useEffect(() => {
    if (!phoneError) {
      debounceGetPaymentService((values[field.name] as string));
    }
  }, [values[field.name], errors, phoneError]);
  const debounceGetPaymentService = debounce((phone: string) => {
    const formattedNumber = formatPhoneNumber(phone, FormatAliasPhone.DIGIT11);
    if (formattedNumber) {
      ewcatApi.getInfoMyMsisdnDo(zone, formattedNumber, optionsAnonymous).then(({
        data
      }) => {
        if (!data.errorCode && data.topUpServiceUniqueId) {
          setMobileServiceByPhoneId(data.topUpServiceUniqueId);
          setMobileServiceByPhoneError(null);
        } else {
          setMobileServiceByPhoneError(data);
        }
      }).catch(error => setMobileServiceByPhoneError(error));
    }
    if (formattedNumber && isAuth) {
      uprsRecommendationApi.getRecommendedPaymentAmount(formattedNumber, optionsAnonymous).then(({
        data
      }) => {
        if (data.amount) {
          setRecommendedAmount(data);
        } else {
          setRecommendedAmount(null);
        }
      });
      custApi.getBalance(zone, uuid(), formattedNumber, scope, optionsAnonymous)
      // eslint-disable-next-line no-underscore-dangle
      .then(({
        data
      }) => setCurrentBalance(data?._embedded?.balances[0]?.accBalances[0]?.total?.amount || null)).catch(() => setCurrentBalance(null));
    }
  }, 700, {
    leading: false,
    trailing: true
  });
  useEffect(() => {
    if (operatorIsNotDefinedError) {
      setCurrentBalance(null);
    }
  }, [operatorIsNotDefinedError]);
  const fetchPayeeData = (id: string) => new Promise((res, rej) => {
    catalogManagerApi.getPayees(zone, uuid(), id, '', true, true, true, optionsAnonymous).then(result => {
      res(result.data.payees[0]);
    }).catch(error => rej(error));
  });
  useEffect(() => {
    if (mobileServiceByPhoneId) {
      fetchPayeeData(mobileServiceByPhoneId).then((payeeData: Payee) => {
        setMobileServiceError(null);
        setMobileService(payeeData);
      }).catch(e => setMobileServiceError(e));
    }
  }, [mobileServiceByPhoneId]);
  const checkServiceAfterBlur = useCallback(() => {
    if (!isEmpty(mobileService)) {
      handleChangeService();
    }
  }, [mobileService]);
  useEffect(() => {
    checkServiceAfterBlur();
  }, [mobileService, checkServiceAfterBlur]);
  const handlePhoneOnBlur = checkServiceAfterBlur;
  const handleChangeService = () => {
    if (mobileService && !phoneError && !mobileServiceByPhoneError) {
      setPayeeData(mobileService);
    }
  };
  return <>
      <FieldsWrapper>
        <FieldWrapper>
          <Field {...field} component={PhoneField} onBlur={handlePhoneOnBlur} size="lg" />
        </FieldWrapper>
        {isBalanceRequired && <FieldWrapper>
            <Field allowDecimal readOnly value={currentBalance} component={MoneyInput} label="Текущий баланс" />
          </FieldWrapper>}
      </FieldsWrapper>
      <>
        {operatorIsNotDefinedError && <Content marginBottom={theme.spacings.xs} marginTop={theme.spacings.xs3}>
            <Text sizemob="md" color={theme.colors.red.active}>
              {operatorSelection}
            </Text>
          </Content>}
      </>
    </>;
};