import React, { FC, useMemo, useRef } from 'react';
import { Formik } from 'formik';
import { Dictionary } from '@reduxjs/toolkit';
import omitBy from 'lodash/omitBy';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { debounce, get, set } from 'lodash';
import { Styled as InputControlStyled } from '@mtsbank/ui-kit/build/components/atoms/InputControl/styled';
import { useToast } from '@mtsbank/ui-kit';
import { FieldProps, WithoutAuthFormValues } from '@components/TspPayee/withoutAuthorization/type';
import { collectServiceParams, serviceParamTypeToComponent } from '@components/TspPayee/utils/serviceUtils';
import { ExtendedServiceParameter } from '@components/TspPayee/types';
import { Payee } from '@open-api/ump/catalog-manager';
import { phoneRegExp } from '@root/constants/regExp';
import { chargeApi } from '@root/api/ump/charge-invoice';
import { uuid } from '@root/utils/generateUuid';
import { SearchParameters } from '@open-api/ump/charge-invoice';
import { SearchInvoicesFields } from '../SearchInvoicesFields';
import { ERROR_PHONE } from '../constants';
import { trimString } from '../utils/helpers';
interface Props {
  serviceInfo: Payee;
  isSearchInvoices: boolean;
  isShowButton: boolean;
  setIsSearchInvoices: (value: boolean) => void;
  setSearchTicketId: (value: string) => void;
}
export const ChargesForm: FC<Props> = ({
  serviceInfo,
  isSearchInvoices,
  isShowButton,
  setIsSearchInvoices,
  setSearchTicketId
}) => {
  const formikRef = useRef(null);
  const {
    toast
  } = useToast();
  const {
    invoiceSearchParams
  } = serviceInfo;
  const fields = useMemo(() => invoiceSearchParams.filter(param => !param.hidden).sort((param: ExtendedServiceParameter) => param.displayOrder).map((param: ExtendedServiceParameter) => {
    const statFunction = noop;
    return ({
      enumId: param.enumId,
      name: param.name,
      label: param.title,
      placeholder: param.description,
      component: serviceParamTypeToComponent[param.type],
      isHidden: param.hidden,
      isClearable: false,
      readOnly: param.readOnly,
      autoComplete: 'off',
      serviceId: serviceInfo?.serviceId,
      ...(param.userHint ? {
        controlChildren: <InputControlStyled.CustomToolTip hint={param.userHint} onMouseOver={statFunction} onClick={statFunction} />
      } : {}),
      ...(param.mask || param.regExp ? {
        mask: omitBy({
          regex: param.regExp,
          mask: param.mask
        }, isEmpty)
      } : {})
    } as FieldProps);
  }, ([] as FieldProps[])), [invoiceSearchParams]);
  const initialValues = useMemo(() => ({
    ...invoiceSearchParams.reduce((preparedParams: Dictionary<string>, param: ExtendedServiceParameter) => ({
      ...preparedParams,
      [param.name]: param.defaultValue
    }), {})
  }), [invoiceSearchParams]);
  const currentValidationSchema = Yup.object().shape({
    ...invoiceSearchParams.reduce((result: ObjectShape, param) => {
      let rules = Yup.string();
      if (!param.hidden) {
        rules = rules.required('Обязательное поле');
      }
      if (param.maxLength && param.type !== 'PhoneField') {
        rules = rules.max(param.maxLength, 'Некорректные данные');
      }
      if (param.regExp && param.type !== 'PhoneField') {
        rules = rules.transform((originalValue: string) => trimString(originalValue, param.mask?.includes(' '))).test('checkRegExp', 'Некорректные данные', (value: string) => {
          if (!value && !param.required) {
            return true;
          }
          const regExp = new RegExp(param.regExp);
          return regExp.test(value);
        });
      }
      if (param.type === 'PhoneField') {
        rules = rules.matches(phoneRegExp, ERROR_PHONE);
      }
      result[param.name] = rules;
      return result;
    }, {})
  });
  const validateValues = () => {
    let errors: Dictionary<string> = {};
    const values = formikRef?.current?.values;
    try {
      currentValidationSchema.validateSync(values, {
        abortEarly: false
      });
    } catch (e) {
      errors = (e.inner || []).reduce((errorsResult, currentError) => {
        if (!get(errorsResult, currentError.path) && formikRef.current.touched[currentError.path]) {
          set(errorsResult, String(currentError?.path), currentError.message);
        }
        return errorsResult;
      }, {});
    }
    return errors;
  };
  const validateDebounced = debounce(resolve => {
    const errors: Dictionary<string> = validateValues();
    resolve(errors);
  }, 20, {
    leading: false,
    trailing: true
  });
  const validateDebouncedPromise = () => new Promise(resolve => {
    validateDebounced(resolve);
  });
  const handleSubmit = () => {
    updateSearchTicketId();
  };
  const updateSearchTicketId = () => {
    const values: Partial<WithoutAuthFormValues> = formikRef?.current?.values;
    const parsedValues = collectServiceParams(invoiceSearchParams || [], values, {
      onlyRequired: false
    });
    setIsSearchInvoices(true);
    chargeApi.searchChargesUsingPOST('mtsmon_site', {
      requestId: uuid(),
      searchParams: ({
        ...parsedValues
      } as SearchParameters)
    }).then(({
      data
    }) => {
      if (!data.errorCode) {
        setSearchTicketId(data.searchTicketId);
      } else {
        toast('error', 'Ошибка поиска счетов', data?.errorMessage || '', {
          withClose: true,
          withTimeout: true,
          timeout: 3000
        });
        setIsSearchInvoices(false);
      }
    }).catch(() => setIsSearchInvoices(false)).finally(() => setIsSearchInvoices(false));
  };
  return <Formik validateOnChange={false} innerRef={formikRef} onSubmit={handleSubmit} validate={validateDebouncedPromise} initialValues={{
    ...initialValues
  }}>
      <SearchInvoicesFields isShowButton={isShowButton} serviceInfo={serviceInfo} fields={fields} isSearchInvoices={isSearchInvoices} />
    </Formik>;
};